













































































































































































































































import { Component, Vue, Prop } from 'vue-property-decorator'
import { addElectronicVoucherOrder, getElectronicVoucherOrderInfo, reviewEVoucherOrders } from '@/api/electronicVoucher'
import Template from '@/views/mall/control-center/shop/pages/template.vue'

@Component({
  components: { Template }
})
export default class electronCardDetail extends Vue {
  batch_info: any[] = []
  phoneData: any[] = [
    {
      phone: '17788889998',
      no: 1234,
      num: 1,
    },
  ]
  tableData: any[] = []
  id: any = ''
  orderInfo: any = {
    log_end: {
      user_id: '',
      audit_node_id:'',
      audit_status:'',
    }
  }
  audit: any = {
    audit_status: '4',
    description: '',
    user_id: '1',
    id: '',
  }
  rules = {
    user_id: [{
      required: true,
      message: '申请人不能为空',
      trigger: 'blur'
    }],
    status: [{
      required: true,
      message: '审批状态不能为空',
      trigger: 'blur'
    }],
    description: [{
      required: true,
      message: '审批意见不能为空',
      trigger: 'blur'
    }],
  }

  onSubmit () {
    const form_ref = this.$refs['form_data'] as HTMLFormElement
    form_ref.validate( async (valid: boolean) => {
      if (!valid) return
      const res = await reviewEVoucherOrders(this.audit);
      this.$router.go(-1);
    })
  }

  async getElectronicVoucherOrder () {
    const electronicVoucherOrderInfo = await getElectronicVoucherOrderInfo({ id: this.id })
    this.audit.id = electronicVoucherOrderInfo.id
    if(electronicVoucherOrderInfo.batch_info !=null){
      this.batch_info.push(electronicVoucherOrderInfo.batch_info)
    }
    this.orderInfo = electronicVoucherOrderInfo
  }
  created () {
    this.id = this.$route.query.id
    this.getElectronicVoucherOrder()
  }
}
